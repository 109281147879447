import { z } from 'zod';

export const BotBodySchemaRequest = z
  .object({
    workflow_id: z.string().nonempty('workflow_id cannot be empty'),
    bot_name: z.string().nonempty('bot_name cannot be empty'),
    is_active: z.boolean().optional(),
    is_custom: z.boolean().optional(),
    input_type: z.array(z.string()).nonempty('input_type cannot be empty'),
    interaction_sequence_num: z.number().optional(),
    system_prompt: z.string().nonempty('system_prompt cannot be empty'),
    user_suggestions: z.array(z.string()).optional(),
    knowledge_files: z.array(z.string()).optional(),
    template_files: z.array(z.string()).optional(),
    llm_of_choice: z.string().nonempty('llm_of_choice cannot be empty'),
    bot_type: z.string().nonempty('bot_type cannot be empty'),
    autonomous: z.string().nonempty('autonomous cannot be empty'),
    save_to_resources: z.boolean(),
    integration_point: z.string().optional(),
    linking_bot_id: z.string().optional(),
    temperature: z.number().optional(),
  })
  .strict();

export const BotBodySchemaResponse = z
  .object({
    bot_id: z.string().nonempty('bot_id cannot be empty'),
    workflow_id: z.string().nonempty('workflow_id cannot be empty'),
    bot_name: z.string().nonempty('bot_name cannot be empty'),
    is_active: z.boolean().optional(),
    is_custom: z.boolean().optional(),
    input_type: z.array(z.string()).optional(),
    interaction_sequence_num: z.number().optional(),
    system_prompt: z.string().optional(),
    user_suggestions: z.array(z.string()).optional(),
    knowledge_files: z.array(z.string()).optional(),
    template_files: z.array(z.string()).optional(),
    llm_of_choice: z.string().optional(),
    bot_type: z.string().optional(),
    autonomous: z.string().optional(),
    save_to_resources: z.boolean().optional(),
    integration_point: z.string().optional(),
    linking_bot_id: z.string().optional(),
    temperature: z.number().optional(),
    created_at: z.string().optional(),
    created_by: z.string().optional(),
    updated_at: z.string().optional(),
    updated_by: z.string().optional(),
  })
  .strict();
