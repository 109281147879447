import { z } from 'zod';

export const workspaceSchemaResponse = z
  .object({
    workspace_id: z.string().nonempty('workspace_id cannot be empty'),
    workspace_name: z.string().nonempty('workspace_name cannot be empty'),
    description: z.string().optional(),
    workspace_industry: z
      .string()
      .nonempty('workspace_industry cannot be empty'),
    workspace_department: z
      .string()
      .nonempty('workspace_department cannot be empty'),
    created_at: z.string().optional(),
    updated_at: z.string().optional(),
    created_by: z.string().optional(),
    updated_by: z.string().optional(),
  })
  .strict();

export const WorkspaceBodySchemaResponse = z
  .object({
    workspace: workspaceSchemaResponse,
  })
  .strict();
