import { z } from 'zod';

export const createMessageResponseSchema = z.object({
  // user_id: z.string().optional(),
  chat_session_id: z.string().nonempty('chat_session_id cannot be empty'),
  message_body: z.string().optional(),
  type: z.enum(['USER']),
  is_error: z.boolean().optional(),
  attachments_url: z.array(z.string()).optional(),
  created_at: z.date().optional(),
  updated_at: z.date().optional(),
  created_by: z.string().optional(),
  updated_by: z.string().optional(),
});
