import { z } from 'zod';

export const relationsType = z.enum(['Owner', 'Editor', 'Admin', 'Viewer']);

export const shareAccessBodySchema = z.object({
  usersToShareResourceWith: z.array(z.string()).nonempty(),
  relation: z.enum([
    'can_view',
    'can_share',
    'can_edit',
    'can_delete',
    'owner',
  ]),
  objectType: z.enum(['workspace', 'workflow']),
  objectId: z.string().nonempty(),
});
