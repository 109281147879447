import { z } from 'zod';

export const UpdateBotBodySchemaRequest = z
  .object({
    bot_id: z.string().nonempty('bot_id cannot be empty'),
    bot_name: z.string().optional(),
    is_active: z.boolean().optional(),
    is_custom: z.boolean().optional(),
    input_type: z.array(z.string()).optional(),
    interaction_sequence_num: z.number().optional(),
    system_prompt: z.string().optional(),
    user_suggestions: z.array(z.string()).optional(),
    knowledge_files: z.array(z.string()).optional(),
    template_files: z.array(z.string()).optional(),
    llm_of_choice: z.string().optional(),
    bot_type: z.string().optional(),
    autonomous: z.string().optional(),
    save_to_resources: z.boolean().optional(),
    integration_point: z.string().nullable().optional(),
    linking_bot_id: z.string().nullable().optional(),
    temperature: z.number().nullable().optional(),
  })
  .strict();
