import { z } from 'zod';

export const messageSchema = z.object({
  message_id: z.string(),
  chat_session_id: z.string(),
  message_body: z.string().optional(),
  type: z.enum(['USER', 'BOT']),
  is_error: z.boolean().optional(),
  attachments_url: z.array(z.string()),
  created_at: z.date().optional(),
  updated_at: z.date().optional(),
  created_by: z.string().optional(),
  updated_by: z.string().optional(),
});
