import { z } from 'zod';

export const userPermissionsResponseSchema = z.object({
  isFreeTrialUser: z.boolean(),
  permissions: z.object({
    workspace: z.array(z.string()),
    workflow: z.array(z.string()),
    bot: z.array(z.string()),
    session: z.array(z.string()),
  }),
});
