import { z } from 'zod';

export const createMessageBodySchema = z.object({
  chat_session_id: z.string().nonempty('chat_session_id cannot be empty'),
  message_body: z.string().optional(),
  type: z.enum(['USER']),
  attachments_url: z.array(z.string()).optional(),
  is_error: z.boolean().optional(),
  runtime_config: z.record(z.string()).optional(),
});
