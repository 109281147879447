import { z } from 'zod';

export const CloneWorkflowBodySchemaRequest = z.object({
  // target_user_id: z.string().optional(),
  previous_workflow_id: z
    .string()
    .nonempty('previous_workflow_id cannot be empty'),
  destination_workspace_id: z
    .string()
    .nonempty('destination_workspace_id cannot be empty'),
});
