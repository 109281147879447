import { z } from 'zod';

export const inviteUsersToPlatfromBodySchema = z.object({
  emailsToInvite: z
    .array(z.string())
    .nonempty('emails to invite cannot be empty'),
  accessStartTime: z.string().optional(),
  accessEndTime: z.string().optional(),
  accessPlan: z.enum(['paid', 'free']),
  accessRole: z.enum(['admin', 'super_admin', 'has_access']).optional(),
});
