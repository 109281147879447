import { z } from 'zod';

export const createUserResponseSchema = z.object({
  user_id: z.string().nonempty('user_id cannot be empty'),
  email: z.string().nonempty('email cannot be empty'),
  first_name: z.string().nonempty('first name cannot be empty'),
  last_name: z.string().nonempty('last name cannot be empty'),
  image: z.string().optional(),
  type: z.string(),
  created_at: z.date().optional(),
  updated_at: z.date().optional(),
  created_by: z.string().optional(),
  updated_by: z.string().optional(),
});
