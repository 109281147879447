import { z } from 'zod';

export const createBotMessageValidation = z.object({
  chat_session_id: z.string().nonempty('chat_session_id cannot be empty'),
  message_body: z.string().optional(),
  message_id: z.string().nonempty('message_id cannot be empty'),
  type: z.enum(['BOT']),
  is_error: z.boolean().optional(),
});

export const receiveWorkflowsControllerMessageSchema = z.object({
  workflow_id: z.string().optional(),
  session_id: z.string().nonempty('session_id cannot be empty'),
  output_data: z.object({
    data: z.string().optional(),
    files_uri: z.array(z.string()).optional(),
  }),
  is_error: z.boolean().optional(),
});

export const receiveMessageSchema = z.union([
  createBotMessageValidation,
  receiveWorkflowsControllerMessageSchema,
]);
