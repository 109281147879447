import { z } from 'zod';

import { BotBodySchemaResponse } from '@/validations/botBodySchemaValidation';

import { AdvancedWorkflowDetailsSchema } from './worksflowRequestBodySchemaValidation';

export const workflowBotsResponseSchema = z.array(
  z.object({
    workflow_id: z.string().nonempty('workflow_id cannot be empty'),
    workflow_name: z.string().nonempty('workflow_name cannot be empty'),
    avatar: z.string().optional(),
    voice: z.string().optional(),
    marketplace: z.boolean().optional(),
    department: z.string().optional(),
    description: z.string().optional(),
    created_at: z.string().optional(),
    updated_at: z.string().optional(),
    created_by: z.string().optional(),
    updated_by: z.string().optional(),
    bots: z.array(BotBodySchemaResponse),
  }),
);

export const advancedWorkflowTextConfigSetEntrySchema = z.object({
  name: z.string(),
  required: z.boolean(),
  is_secret: z.boolean(),
  option_set: z.null(),
  description: z.string(),
  display_name: z.string(),
  default_value: z.string().optional(),
});

export const advancedWorkflowDropdownConfigSetEntrySchema = z.object({
  name: z.string(),
  required: z.boolean(),
  is_secret: z.boolean(),
  option_set: z.array(
    z.object({
      name: z.string(),
      description: z.string(),
      display_name: z.string(),
    }),
  ),
  description: z.string(),
  display_name: z.string(),
  default_value: z.string().optional(),
});

export const workflowBotsObjectResponseSchema = z
  .object({
    workflow_id: z.string().nonempty('workflow_id cannot be empty'),
    workflow_name: z.string().nonempty('workflow_name cannot be empty'),
    avatar: z.string().optional(),
    voice: z.string().optional(),
    marketplace: z.boolean().optional(),
    department: z.string().optional(),
    description: z.string().optional(),
    created_at: z.string().optional(),
    updated_at: z.string().optional(),
    created_by: z.string().optional(),
    updated_by: z.string().optional(),
    bots: z.array(BotBodySchemaResponse),
    free_workflow: z.boolean().optional(),
    advanced_workflow: z.boolean().optional(),
    advanced_workflow_details: AdvancedWorkflowDetailsSchema,
  })
  .strict();
