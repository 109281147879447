import { z } from 'zod';

export const UpdateWorkspaceBodySchemaRequest = z
  .object({
    workspace_id: z.string().nonempty('workspace_id cannot be empty'),
    workspace_name: z.string().optional(),
    description: z.string().optional(),
    workspace_industry: z.string().optional(),
    workspace_department: z.string().optional(),
  })
  .strict();
