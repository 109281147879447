import { z } from 'zod';

export const updateChatSessionResponseSchema = z.object({
  chat_session_id: z.string().nonempty('chat_session_id cannot be empty'),
  chat_session_name: z.string().nonempty('chat_session_name cannot be empty'),
  // user_id: z.string().optional(),
  agent_id: z.string().nonempty('agent_id cannot be empty'),
  start_time: z.date().optional(),
  end_time: z.date().optional(),
  runtime_config_data: z.record(z.string()).optional(),
  created_at: z.date().optional(),
  updated_at: z.date().optional(),
  created_by: z.string().optional(),
  updated_by: z.string().optional(),
});
