import { z } from 'zod';

export const WorkflowBodySchemaRequest = z
  .object({
    workspace_id: z.string().nonempty('workspace_id cannot be empty'),
    workflow_name: z.string().nonempty('workflow_name cannot be empty'),
    avatar: z.string().nonempty('avatar cannot be empty'),
    voice: z.string().optional(),
    department: z.string().optional(),
    description: z.string().optional(),
    marketplace: z.boolean().optional(),
  })
  .strict();

export const WorkflowBodySchemaUpdateRequest = z
  .object({
    workflow_id: z.string().nonempty('workflow_id cannot be empty'),
    workflow_name: z.string().optional(),
    avatar: z.string().optional(),
    voice: z.string().optional(),
    marketplace: z.boolean().optional(),
    department: z.string().optional(),
    description: z.string().optional(),
    published_workflow: z.boolean().optional(),
  })
  .strict();

const configSetEntry = z.object({
  name: z.string().optional(),
  display_name: z.string().optional(),
  description: z.string().optional(),
  is_secret: z.boolean(),
  option_set: z.any().nullable(),
  default_value: z.any().nullable(),
  required: z.boolean(),
});

export type ConfigSetEntry = z.infer<typeof configSetEntry>;

export const INPUT_TYPES = z.enum([
  'text_only',
  'files_only',
  'text_and_files',
  'text_or_files',
  'any',
]);
export const OUTPUT_TYPES = z.enum([
  'text_only',
  'files_only',
  'text_and_files',
  'text_or_files',
]);

export const BaseAdvancedWorkflowDetailsSchema = z
  .object({
    name: z.string().nonempty('name cannot be empty'),
    publish_name: z.string().nonempty('publish name cannot be empty'),
    description: z.string().optional(),
    version: z.string().nonempty('version cannot be empty'),
    department: z.string().optional(),
    knowledge_base: z.object({
      supported: z.boolean(),
      formats: z.array(z.string()).nullable(),
      maximum_number_of_files: z.number().nullable(),
    }),
    workflow_type: z.string().optional(),
    workflow_config_set: z.array(configSetEntry),
    runtime_config_set: z.array(
      z.object({
        name: z.string().optional(),
        display_name: z.string().optional(),
        description: z.string().optional(),
        is_secret: z.boolean(),
        option_set: z.array(z.string()).nullable(),
        default_value: z.string().nullable(),
        required: z.boolean(),
      }),
    ),
    input: z.object({
      expected: INPUT_TYPES.optional(),
      file_formats: z.array(z.string()).nullable(),
      maximum_number_of_files: z.number().nullable(),
    }),
    output: z.object({
      expected: OUTPUT_TYPES.optional(),
      file_formats: z.array(z.string()).nullable(),
      maximum_number_of_files: z.number().nullable(),
    }),
    suggestions: z.array(z.string()).nullable().optional(),
    is_production_ready: z.boolean().optional(),
    call_to_action: z.string().optional(),
  })
  .strict();

export const AdvancedWorkflowDetailsSchema =
  BaseAdvancedWorkflowDetailsSchema.refine(
    (data) => {
      if (data.workflow_type === 'one_time') {
        return (
          data.call_to_action !== undefined && data.call_to_action.trim() !== ''
        );
      }
      return true;
    },
    {
      message: "call_to_action is required when workflow_type is 'one_time'",
      path: ['call_to_action'],
    },
  );
