import { z } from 'zod';

export const WorkspaceBodySchemaRequest = z
  .object({
    workspace_name: z.string().nonempty('workspace_name cannot be empty'),
    description: z.string().optional(),
    workspace_industry: z
      .string()
      .nonempty('workspace_industry cannot be empty'),
    workspace_department: z
      .string()
      .nonempty('workspace_department cannot be empty'),
  })
  .strict();
