import { z } from 'zod';

import { BaseAdvancedWorkflowDetailsSchema } from './worksflowRequestBodySchemaValidation';

export const workflowSchemaResponse = z
  .object({
    workflow_id: z.string().nonempty('workflow_id cannot be empty'),
    workflow_name: z.string().nonempty('workflow_name cannot be empty'),
    avatar: z.string().optional(),
    voice: z.string().optional(),
    marketplace: z.boolean().optional(),
    published_workflow: z.boolean().optional(),
    description: z.string().optional(),
    department: z.string().optional(),
    free_workflow: z.boolean().optional(),
    created_at: z.string().optional(),
    updated_at: z.string().optional(),
    created_by: z.string().optional(),
    updated_by: z.string().optional(),
    advanced_workflow: z.boolean().optional(),
    advanced_workflow_details: BaseAdvancedWorkflowDetailsSchema,
  })
  .strict();

export const WorkflowBodySchemaResponse = z.object({
  workflow: workflowSchemaResponse,
});
