import { initClient } from '@ts-rest/core';
import { initQueryClient } from '@ts-rest/react-query';

import { validationContract } from '@/app/contracts/validationContract';

export const queryClient = initQueryClient(validationContract, {
  baseUrl: (process.env.NEXT_PUBLIC_CONTRACT_BASE_URL as string) ?? '/api',
  baseHeaders: {},
});

export const fetchQueryClient = initClient(validationContract, {
  baseUrl: (process.env.NEXT_PUBLIC_CONTRACT_BASE_URL as string) ?? '/api',
  baseHeaders: {},
});
