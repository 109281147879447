import { z } from 'zod';

export const updateChatSessionBodySchema = z
  .object({
    bot_id: z.string().optional(),
    workflow_id: z.string().optional(),
    chat_session_id: z.string().nonempty('chat_session_id cannot be empty'),
    chat_session_name: z.string().optional(),
    runtime_config_data: z.record(z.string()).optional(),
  })
  .refine(
    (data) => {
      if (!data.bot_id && !data.workflow_id) {
        throw new Error('Either bot_id or workflow_id must be provided');
      }
      if (data.bot_id && data.workflow_id) {
        throw new Error(
          'Both bot_id and workflow_id cannot be provided simultaneously',
        );
      }
      return true;
    },
    {
      message: 'Invalid combination of bot_id and workflow_id',
    },
  );
